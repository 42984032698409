import './App.css';
import Header from './components/header/Header';
import Main from './components/mainSection/Main';
import Footer from './components/footer/Footer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
