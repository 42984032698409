import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className='bg-[#1B1B1B] flex flex-wrap justify-center gap-24 py-16 px-4 sm:px-16 md:px-0'>
            <div className='md:w-2/6'>
                <img src='/assets/logo/logo-white.png' className='h-10' alt='logo' />
                <p className='text-white mt-5 font-medium text-sm'>Neptune Forex CRM, a comprehensive solution designed specifically for brokers, encompassing a back office, trader’s room, and advanced live monitoring system compatible with MT5, and Vertex platforms</p>
                <div className='flex flex-wrap items-center gap-4 mt-5'>
                    <img src='/img/dest/icons/facebook.svg' className='cursor-pointer' alt='icon' />
                    <img src='/img/dest/icons/linkedin.svg' className='cursor-pointer' alt='icon' />
                    <img src='/img/dest/icons/youtube.svg' className='cursor-pointer' alt='icon' />
                    <img src='/img/dest/icons/instagram.svg' className='cursor-pointer' alt='icon' />
                    <img src='/img/dest/icons/telegram.svg' className='cursor-pointer' alt='icon' />
                </div>
            </div>
            <div>
                <p className='text-white font-bold text-sm'>Menu</p>
                <Link to='/'>
                    <p className='text-[#FFFFFFCC] font-bold mt-4 cursor-pointer text-sm'>Home</p>
                </Link>
            </div>
            <div>
                <p className='text-white font-bold text-sm'>Our Polices</p>
                <Link to='/privacy-policy'>
                    <p className='text-[#FFFFFFCC] font-bold mt-4 cursor-pointer text-sm'>Terms & Condition</p>
                    <p className='text-[#FFFFFFCC] font-bold mt-2 cursor-pointer text-sm'>Privacy Policy</p>
                </Link>
            </div>
        </div>
    )
}

export default Footer