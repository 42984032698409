import React, { useEffect } from 'react';

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="container mx-auto py-10 lg:px-0 px-12 text-base lg:max-w-screen-lg">
            <h1 className="text-3xl font-bold mb-6 ">Privacy Policy</h1>

            <p className="mb-4">
                We care about your right to privacy. Regarding any information we may collect about you through our website <b>www.neptunefxcrm.com</b> or other channels we own or administer, this is a fundamental policy of Neptune.
            </p>
            <p className="mb-6 ">
                As a Forex CRM provider, we are committed to maintaining the confidentiality and security of your personal information. We only request and gather personal information when we actually need it to help you. We gather this data using ethical and legal methods with your awareness and consent. We also let you know why we’re gathering the info and how we plan to use it.
            </p>

            <h2 className="text-2xl font-semibold mb-4">Information We Collect</h2>
            <ul className="list-disc list-inside mb-6 ">
                <li>
                    <strong>Personal Identification Information:</strong> This may include
                    your name, email address, phone number, and company details.
                </li>
                <li>
                    <strong>Financial Information:</strong> As a Forex CRM, we may collect
                    financial data related to your trading activities, including
                    transaction history and account details.
                </li>
                <li>
                    <strong>Usage Data:</strong> We gather data on how you interact with
                    our services, including your IP address, browser type, and pages
                    visited.
                </li>
            </ul>

            <h2 className="text-2xl font-semibold mb-4 ">Use of Information</h2>
            <p className="mb-4 ">We use the information we collect to:</p>
            <ul className="list-disc list-inside mb-6 ">
                <li>Provide and maintain our Forex CRM services.</li>
                <li>Improve our services and enhance user experience.</li>
                <li>Communicate with you about your account, transactions, and services.</li>
                <li>Offer customer support and respond to your inquiries.</li>
                <li>Conduct research and analysis to improve our offerings.</li>
            </ul>

            <p className="mb-6 ">
                We only keep the data we collect for as long as it takes to deliver the
                services you've requested.
            </p>

            <p className="mb-6 ">
                Whatever information we store, we’ll safeguard it using legally
                permissible methods to avoid loss, theft, unauthorized access,
                disclosure, copying, abuse, or any modifications.
            </p>

            <p className="mb-6 ">
                Except when required by law, we don’t publicly share or otherwise make
                available any personal information about you or any third-party entity.
            </p>

            <p className="mb-6 ">
                Neptune’s website might have connections to external websites that we
                don’t operate. Heads up: We have zero control over their info or
                business activities, and we’re not responsible for their content or
                privacy practices.
            </p>

            <h2 className="text-2xl font-semibold mb-4 ">Data Security</h2>
            <p className="mb-6 ">
                We implement a range of security measures to protect your personal
                information, including encryption, secure servers, and regular security
                audits.
            </p>

            <h2 className="text-2xl font-semibold mb-4 ">Your Rights</h2>
            <p className="mb-6 ">
                You have the right to access, correct, or delete your personal
                information at any time. You can also object to the processing of your
                data or request the restriction of processing in certain circumstances.
            </p>

            <p className="mb-6 ">
                You’ve got the freedom to refuse our request for personal information,
                with the understanding that we may not be able to provide some of the
                services you want (because, well, privacy!).
            </p>

            <p className="mb-6 ">
                Your continued use of our website will be considered as you being cool
                with our privacy practices and how we handle personal info. If you have
                any questions about how we deal with your data and personal information,
                feel free to contact us at
                <a href="mailto:info@neptunefxcrm.com" className="text-blue-500 hover:underline ms-2">
                    info@neptunefxcrm.com
                </a>.
            </p>

        </div>
    );
};

export default PrivacyPolicy;
