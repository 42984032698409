import React, { useEffect } from 'react'
import Intro from './subSection/Intro'
import Companies from './subSection/Companies'
import IntroductionVideo from './subSection/IntroductionVideo'
import BrokerConnection from './subSection/BrokerConnection'
import FinacialConnection from './subSection/FinacialConnection'
import JoinUs from './subSection/JoinUs'
import WhatIsFinacial from './subSection/WhatIsFinacial'
import EscrowDifference from './subSection/EscrowDifference'
import HowMuch from './subSection/HowMuch'
import Escrow from './subSection/Escrow'
import Advisors from './subSection/Advisors'
import IndustryLeader from './subSection/IndustryLeader'
import Feedback from './subSection/Feedback'
import Blogs from './subSection/Blogs'
import CountriesServe from './subSection/CountriesServe'

const Main = () => {
    useEffect(() => {
        window.scrollTo(0, 0); 
      }, []);
    return (
        <>
            <main>
                <Intro />
                <Companies />
                <IntroductionVideo />
                <BrokerConnection />
                <FinacialConnection />
                <JoinUs />
                <WhatIsFinacial />
                <EscrowDifference />
                <HowMuch />
                <Escrow />
                <Blogs />
                <CountriesServe />
                {/* <Advisors/> */}
                {/* <IndustryLeader/> */}
                <Feedback />
            </main>
        </>
    )
}

export default Main